/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { DeepBI } from "../../integrations";
import styles from "./audio-magazine.m.css";
import { get } from "lodash";
import Icon from "../atoms/icon";
import ResponsiveImageWithFallback from "../atoms/responsive-image-with-fallback";
import { generateImageSources } from "../utils/utils";
import { connect } from "react-redux";
import assetify from "@quintype/framework/assetify";
import loader from "../../../assets/images/loader.gif";
import { LoadmoreAudiostories, PostVikatanplayrating, GetVikatanplayrating } from "../../api";
import { Link, WithHostUrl } from "@quintype/components";
import MobileLogin from "../rows/header/user-login/login-mobile";
const sign = require("jwt-encode");

// import Contributor from "../atoms/contributor";
class AudioMagazineEpisode extends React.Component {
  constructor() {
    super();
    this.state = {
      playing: false,
      currentStoryId: null,
      playingAll: false,
      visibleItems: [], // Stories that are currently visible
      offset: 0, // Start with an initial offset of 0 (will update with preloaded stories)
      limit: 10, // Number of stories to fetch per API request
      loading: false, // Track the loading state
      hasMoreStories: false, // Whether there are more stories to load
      collectionName: "",
      showRatingPopup: false,
      rating: 2,
      name: "",
      review: "",
      UserId: "",
      averageRating: 0,
      totalRating: 0,
      ratings: {},
      showThankYouMessage: false,
      isSubmitting: false,
      showFullTitle: false
    };
  }

  toggleReadMore = () => {
    this.setState(prevState => ({ showFullTitle: !prevState.showFullTitle }));
  };

  componentDidMount() {
    const { data } = this.props;
    const initialStories = get(data, ["collection", "items"], []);
    const totalStoryCount = get(data, ["collection", "total-count"], 0);
    const collectionName = get(data, ["collection", "slug"], "");
    const collectionId = get(data, ["collection", "id"], "");

    // Set initial state
    this.setState({
      visibleItems: initialStories,
      totalStoryCount,
      offset: initialStories.length,
      hasMoreStories: initialStories.length < totalStoryCount,
      collectionName,
      activeTab: "description"
    });

    // Fetch user data once and update state
    global.userPromise.catch(() => ({})).then((user = {}) => {
      const userId = user.userId || ""; // Ensure userId is always a string

      this.setState(
        {
          userType: user.userType,
          userStatus: user.loggedOut,
          UserId: userId // Update UserId safely
        },
        () => {
          // Run this AFTER `UserId` is updated
          this.checkForAudioBook(initialStories);

          // Fetch rating only after `UserId` is updated
          const body = {
            userId: userId, // Now using the updated userId
            collectionId: collectionId
          };

          GetVikatanplayrating(body)
            .then(response => {
              const averageRating = get(response, "reviewList.rating.averageRating", 0);
              const totalRating = get(response, "reviewList.rating.totalRating", 0);
              const ratings = get(response, "reviewList.rating.ratings", {});
              const userRating = get(response, "reviewList.rating.userRating", 4);
              const userReview = get(response, "reviewList.rating.userReview", "");
              const username = get(response, "reviewList.rating.username", "");

              this.setState({
                averageRating,
                totalRating,
                ratings,
                rating: userRating,
                review: userReview,
                name: username
              });
            })
            .catch(console.error);
        }
      );
    });
  }

  checkForAudioBook = initialStories => {
    // Get the pathname from the URL
    const pathname = window && window.location && window.location.pathname;
    const slugSegments = pathname.split("/").filter(Boolean); // Split and filter out empty strings
    const lastSegment = slugSegments[slugSegments.length - 1]; // Get the last part of the path

    // Check if the last segment is a valid story slug
    // Assuming a story slug is alphanumeric and not purely numeric
    const isStorySlug = isNaN(Number(lastSegment)); // Check if it's not a number

    if (isStorySlug) {
      // Find the matching story in the initialStories collection
      const matchingStory = initialStories.find(item => {
        const slug = get(item, ["story", "slug"], ""); // Full slug from the story
        const storySlugSegments = slug.split("/"); // Split the story's slug
        const storyLastSegment = storySlugSegments[storySlugSegments.length - 1]; // Get the last part of the story's slug

        // Compare the last segment of the URL with the story's slug
        return lastSegment === storyLastSegment;
      });

      if (matchingStory) {
        const story = get(matchingStory, ["story"], null); // Extract the story object
        if (story) {
          this.handlePlay(story); // Handle the play action for the matching story
        }
      }
    }
  };

  componentDidUpdate(prevProps) {
    const { currentPlayingStory, PlayAudio } = this.props;

    // Check if PlayAudio has changed or if the current story has changed
    if (
      PlayAudio !== prevProps.PlayAudio ||
      (currentPlayingStory && currentPlayingStory.id !== this.state.currentStoryId)
    ) {
      if (currentPlayingStory) {
        // Update the state to reflect the new story
        this.setState({
          playing: PlayAudio, // Or true/false based on PlayAudio
          currentStoryId: currentPlayingStory.id
        });
      } else {
        // Handle case where there is no currentPlayingStory
        this.setState({
          playing: false,
          currentStoryId: null
        });
      }
    }
  }

  // Function to fetch stories from the API
  fetchStories = async offset => {
    const { data } = this.props;

    const collectionslug = get(data, ["collection", "slug"], "");

    const { limit, visibleItems, collectionName } = this.state;
    const collectionId = collectionName || collectionslug; // Replace with your actual collectionId

    this.setState({ loading: true });

    LoadmoreAudiostories(collectionId, limit, offset)
      .then(res => {
        // Assuming the API response contains stories
        const newStories = res.items || [];

        this.setState(prevState => ({
          visibleItems: [...prevState.visibleItems, ...newStories], // Append new stories to existing ones
          offset: prevState.offset + limit, // Update offset for the next fetch
          loading: false, // Set loading to false
          hasMoreStories: visibleItems.length + newStories.length < prevState.totalStoryCount // Check if there are more stories to load
        }));
      })
      .catch(error => {
        console.error(error);
        this.setState({ loading: false }); // Set loading to false on error
      });
  };

  generateJWT(payload) {
    const secret = this.props.jwtKey;
    const jwt = sign(payload, secret);
    return jwt;
  }

  // Handle loading more stories on button click
  handleLoadMore = () => {
    const { offset, hasMoreStories } = this.state;

    if (hasMoreStories) {
      this.fetchStories(offset); // Fetch the next set of stories using the updated offset
    }
  };

  handleTogglePlayCurrentStory = () => {
    const { playing, currentStoryId } = this.state;

    if (playing) {
      this.handlePause(); // Pause the currently playing story
      this.setState({ playingAll: true }, () => {
        this.handlePlayFirstStory(); // Start first story of new collection
      });
    } else if (currentStoryId) {
      this.handlePlayById(currentStoryId); // Play the currently active story
    } else {
      this.handlePlayFirstStory(); // No story is playing, so start with the first one
    }
  };

  handlePlayFirstStory = () => {
    const { data } = this.props;
    const items = get(data, ["collection", "items"], []); // Get the stories from the collection
    const firstStory = get(items, [0, "story"]); // Access the first story

    if (firstStory) {
      this.handlePlay(firstStory); // Reuse the existing handlePlay method
    }
  };

  // This method allows playing a story by ID
  handlePlayById = storyId => {
    const { items } = this.props.data.collection;
    const foundItem = items.find(item => get(item, ["story", "id"]) === storyId);
    const story = foundItem && foundItem.story ? foundItem.story : undefined;

    if (story) {
      this.handlePlay(story);
    }
  };
  handlePlay = story => {
    if (this.state.userStatus) {
      this.setState({ showLoginModal: true });
      DeepBI.PingLoginevents("Login_widget_view", "", "", "", "", "vikatan_play_player");
      return;
    }

    const storyId = get(story, ["id"]);
    this.props.setCurrentStory(story);
    this.props.setCurrentCollection(this.props.data.collection);
    this.props.setMiniPlayerVisibility(true); // Dispatching action to Redux
    this.props.setAudioplaying(true);
    this.setState({ playing: true, currentStoryId: storyId });

    if (this.props.ClosePlayer) {
      this.props.setClosePlayer(false);
    }
  };

  handlePause = story => {
    this.props.setAudioplaying(false);
    this.setState({ playing: false, currentStoryId: null });
    // props.setCurrentStory(story);
  };

  handleTabChange = tab => {
    this.setState({ activeTab: tab });
  };

  handleMobileLoginClose = () => {
    this.setState({ showLoginModal: false });
  };

  handleToggleRatingPopup = () => {
    if (this.state.userStatus) {
      this.setState({ showLoginModal: true });
      DeepBI.PingLoginevents("Login_widget_view", "", "", "", "", "vikatan_play_player");
      return;
    }
    this.setState(prevState => ({ showRatingPopup: !prevState.showRatingPopup }));
  };

  handleCloseRatingPopup = () => {
    this.setState({ showRatingPopup: false });
  };

  formatTime = time => {
    if (isNaN(time)) {
      return "00:00";
    }
    const remainder = time % 3600;
    const minutes = Math.floor(remainder / 60);
    const seconds = Math.floor(remainder % 60);

    const mm = minutes.toString().padStart(2, "0");
    const ss = seconds.toString().padStart(2, "0");

    return `${mm}:${ss}`;
  };

  handleStarClick = rating => {
    this.setState({ rating });
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async e => {
    const { rating, name, review, UserId } = this.state;

    const { data } = this.props;

    const collectionId = get(data, ["collection", "id"], "");

    e.preventDefault();

    // Validation check
    if (!name.trim()) {
      this.setState({ error: "Name is required!" });
      setTimeout(() => this.setState({ error: "" }), 3000); // Clear error after 3s

      return;
    }

    if (rating === 0) {
      this.setState({ error: "Please select a rating!" });
      setTimeout(() => this.setState({ error: "" }), 3000); // Clear error after 3s

      return;
    }

    const body = {
      user_id: UserId,
      collection_id: collectionId,
      rating: rating,
      name: name,
      review: review
    };
    this.setState({ isSubmitting: true });
    try {
      const jwtToken = this.generateJWT(body);

      // Prepare API payload
      const requestData = { reqData: jwtToken };

      const response = await PostVikatanplayrating(requestData);

      if (response.success) {
        this.setState({
          rating: response.rating,
          name: "",
          review: "",
          error: "",
          showRatingPopup: false,
          isSubmitting: false,
          showThankYouMessage: true
        });

        // this.props.onClose(); // Close the popup after submission
      } else {
        this.setState({ isSubmitting: false, error: "" });
      }
    } catch (error) {
      this.setState({ isSubmitting: false, error: "" });
    }
  };

  handleOkClick = async () => {
    try {
      const { UserId } = this.state;
      const { data } = this.props;
      const collectionId = get(data, ["collection", "id"], "");

      // Fetch updated rating data
      const ratingResponse = await GetVikatanplayrating({
        userId: UserId,
        collectionId: collectionId
      });

      // Extract updated rating details
      const updatedAverageRating = get(ratingResponse, "reviewList.rating.averageRating", 0);
      const updatedTotalRating = get(ratingResponse, "reviewList.rating.totalRating", 0);
      const updatedRatings = get(ratingResponse, "reviewList.rating.ratings", {});
      const updatedUserRating = get(ratingResponse, "reviewList.rating.userRating");
      const updatedUserReview = get(ratingResponse, "reviewList.rating.userReview", "");
      const updatedUsername = get(ratingResponse, "reviewList.rating.username", "");

      // Update state with new ratings & hide thank you message
      this.setState({
        rating: updatedUserRating,
        averageRating: updatedAverageRating,
        totalRating: updatedTotalRating,
        ratings: updatedRatings,
        name: updatedUsername,
        review: updatedUserReview,
        showThankYouMessage: false // Hide thank you popup after fetching ratings
      });
    } catch (error) {
      console.error("Error fetching updated ratings:", error);
      this.setState({ showThankYouMessage: false }); // Hide popup even if API fails
    }
  };

  audiomagazineCollectionPath = slug => {
    return `/vikatan-play/${slug}`;
  };
  render() {
    const { data } = this.props;
    const { rating, name, review, averageRating, totalRating, ratings } = this.state;
    const ratingKeys = [5, 4, 3, 2, 1]; // Ensure correct order

    const Count = get(data, ["collection", "total-count"], 0);
    const collectionName = get(data, ["collection", "name"]);
    const collectionSlug = get(data, ["collection", "id"], "");

    const LinkSlug = get(data, ["collection", "slug"], "");

    const collectionImage = get(data, ["collection", "metadata", "cover-image", "cover-image-s3-key"], "");
    const collectionImageMetaData = get(data, ["collection", "metadata", "cover-image", "cover-image-metadata"], {});
    // const MetaDataDesc = get(data, ["collection", "metadata", "meta-description"]);
    const snapshot = get(data, ["collection", "metadata", "snapshot", "body"], "")
      .split("</p>") // Splitting into an array
      .map(str => str.replace(/<\/?[^>]+(>|$)/g, "").trim()) // Remove all HTML tags properly
      .filter(str => str.length); // Remove empty elements

    const collectionItems = get(data, ["collection", "items"], "");

    const authorName = get(collectionItems, [0, "story", "author-name"], "Unknown");

    const items = get(data, ["collection", "items"], []); // Get the stories from the collection
    const firstStory = get(items, [0, "story"]); // Access the first story

    const firstslug = get(firstStory, ["slug"]);

    const { visibleItems, loading, hasMoreStories, showFullTitle } = this.state;

    return (
      <React.Fragment>
        <div className={`js-story-main-container ${`${styles["base"]}`} ${`${styles["magazine-story-page"]}`}`}>
          <div className="container">
            <ul className={styles["collection-breadcrums"]} itemScope itemType="http://schema.org/BreadcrumbList">
              <li
                className={styles.breadcrumb}
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ListItem"
              >
                <WithHostUrl>
                  {({ primaryHostUrl }) => (
                    <Link
                      aria-label="Vikatan"
                      href={primaryHostUrl ? primaryHostUrl + "/vikatan-play" + "/" : "/"}
                      itemprop="item"
                    >
                      <span itemProp="name">Home</span>
                    </Link>
                  )}
                </WithHostUrl>
                <span>/</span>

                <meta itemProp="position" content={1} />
              </li>
              {collectionName && (
                <li
                  className={styles.breadcrumb}
                  itemProp="itemListElement"
                  itemScope
                  itemType="http://schema.org/ListItem"
                >
                  <Link
                    aria-label={`${"Navigate to "} ${this.audiomagazineCollectionPath(LinkSlug)}`}
                    href={this.audiomagazineCollectionPath(LinkSlug)}
                    itemprop="item"
                  >
                    <span itemProp="name">{collectionName}</span>
                  </Link>
                  <meta itemProp="position" content={2} />
                </li>
              )}
            </ul>
          </div>
          {this.state.showLoginModal && <MobileLogin onClose={this.handleMobileLoginClose} />}

          {visibleItems && visibleItems.length > 0 ? (
            <div className={`${styles["episode-page"]} container`}>
              {this.state.showRatingPopup && (
                <div className={styles["rating-popup-wrapper"]}>
                  <div className={styles["rating-popup"]}>
                    <div>
                      <div className={styles["rating-popup-close"]} onClick={this.handleCloseRatingPopup}>
                        X
                      </div>
                      <div>
                        <div className={styles["collection-popup-image"]}>
                          <ResponsiveImageWithFallback
                            className={styles["image-wrapper"]}
                            slug={collectionImage}
                            metadata={collectionImageMetaData}
                            alt={collectionName}
                            imgParams={{ auto: ["format", "compress"] }}
                            sources={generateImageSources(
                              { aspectRatio: [4, 3], screenWidthCoverage: 0.75 },
                              { aspectRatio: [4, 3], screenWidthCoverage: 0.3 }
                            )}
                          />
                        </div>
                        <h5 className={styles["popup-title"]}>{collectionName}</h5>

                        <div className={styles["popup-rating"]}>
                          {[1, 2, 3, 4, 5].map(star => (
                            <span
                              key={star}
                              onClick={() => this.handleStarClick(star)}
                              className={`${star <= rating ? styles["star-filled"] : styles["star-empty"]} ${
                                styles["star-pointer"]
                              }`}
                            >
                              <Icon type={"icon-star"} />
                            </span>
                          ))}
                        </div>

                        <div className={styles["input-text"]}>
                          <input
                            type="text"
                            placeholder="Enter your name"
                            value={name}
                            onChange={this.handleInputChange}
                            name="name"
                          />
                        </div>
                        <div className={styles["input-textarea"]}>
                          <textarea
                            type="text"
                            name="review"
                            placeholder="Type Your Review"
                            value={review}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className={styles["input-button"]}>
                          <button onClick={this.handleSubmit}>Submit</button>
                        </div>
                        <div className={styles["error-empty"]}>
                          {this.state.error && <p className={styles["error-message"]}>{this.state.error}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {this.state.isSubmitting && (
                <div className={styles["loader-wrapper"]}>
                  <img alt="Vikatan" src={assetify(loader)} className={styles["loader"]} />
                </div>
              )}

              {this.state.showThankYouMessage && (
                <div className={styles["thankyou-section-wrapper"]}>
                  <div className={styles["thankyou-section"]}>
                    <h6>Thank You!</h6>
                    <p>Your ratings are submitted successfully.</p>
                    <div>
                      <button onClick={this.handleOkClick}>OK</button>
                    </div>
                  </div>
                </div>
              )}

              <div className={`${styles["episode-page-grid"]}`}>
                <div className={`${styles["top-collection"]}`}>
                  <div className={styles["cover-image"]}>
                    <ResponsiveImageWithFallback
                      className={styles["image-wrapper"]}
                      slug={collectionImage}
                      metadata={collectionImageMetaData}
                      alt={collectionName}
                      imgParams={{ auto: ["format", "compress"] }}
                      sources={generateImageSources(
                        { aspectRatio: [4, 3], screenWidthCoverage: 0.75 },
                        { aspectRatio: [4, 3], screenWidthCoverage: 0.3 }
                      )}
                    />
                  </div>

                  <div>
                    <h1 className={styles["collection-title"]}>{collectionName}</h1>
                    <div className={`${styles["collection-audio-bar"]} ${styles["episode-audio-bar"]}`}>
                      <div className={styles["author-total-timer"]}>
                        <Icon type="author" />
                        {authorName}
                      </div>

                      <Link
                        href={`/vikatan-play/${collectionSlug}/${firstslug}`}
                        callback={() => {
                          this.handleTogglePlayCurrentStory();
                        }}
                      >
                        <div className={styles["collection-cta-bar"]}>
                          {this.state.playingAll ? (
                            <Icon type="icon-pause" className={`${styles["pause"]} ${styles["audio-icon"]}`} />
                          ) : (
                            <Icon type="icon-play" className={`${styles["play"]} ${styles["audio-icon"]}`} />
                          )}
                          <span className={styles["play-episodes"]}>
                            {this.state.playingAll ? "Pause Episode" : "Play Episodes"}
                          </span>
                        </div>
                      </Link>

                      <div>
                        <Link href="https://vikatanmobile.page.link/home-page">
                          <div className={styles["install-appbar"]}>
                            <Icon type="icon-apple" className={styles["apple-icon"]} />
                            <Icon type="icon-google-play" className={styles["play-icon"]} />
                            Install app & Get Free Trial
                          </div>
                        </Link>
                      </div>

                      <div className={styles["rating-wrapper"]}>
                        <div className={styles["rating-header"]}>
                          <div>Readers Rating</div>
                          <div className={styles["rating-header-cta"]} onClick={this.handleToggleRatingPopup}>
                            Rate this series
                          </div>
                        </div>

                        <div className={styles["rating-section"]}>
                          {/* Overall Rating */}
                          <div className={styles["overall-rating"]}>
                            <h6 className={styles["total-rating"]}>{averageRating.toFixed(1)}</h6>
                            <p>({totalRating} Ratings)</p>
                          </div>

                          {/* Rating Progress Bars */}
                          <div className={styles["rating-progress"]}>
                            {ratingKeys.map(star => (
                              <div key={star} className={styles["rating-progress-section"]}>
                                <p>{star}</p>
                                <Icon type="icon-star" />
                                <progress
                                  className={styles["custom-progress"]}
                                  value={(ratings[star] || 0) * 100} // Handle undefined ratings
                                  max="100"
                                />
                                <p>{Math.round((ratings[star] || 0) * 100)}%</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  {/* {firstTitle && (
                    <div>
                      <h4 className={styles["tab-title"]}>
                        <span className={styles["tab-title-content"]}>Description</span>
                      </h4>
                      <div className={styles["description"]}>{firstTitle.replace(/(<([^>]+)>)/gi, "")}</div>
                    </div>
                  )} */}

                  {snapshot && (
                    <div>
                      <div>
                        <h4 className={styles["tab-title"]}>
                          <span className={styles["tab-title-content"]}>Description</span>
                        </h4>
                      </div>
                      <div className={styles["description"]}>
                        <div>
                          {showFullTitle
                            ? snapshot.join(" ") // Convert array to string safely
                            : `${snapshot.join(" ").substring(0, 230)}...`}
                          {snapshot.join(" ").length > 230 && ( // Show button only if length > 230
                            <button onClick={this.toggleReadMore} className={styles["read-more-btn"]}>
                              {showFullTitle ? "Read Less" : "Read More"}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className={styles["eposide-section"]}>
                    <h4 className={styles["tab-title"]}>
                      <span className={styles["tab-title-content"]}>Episodes ({Count}) </span>
                    </h4>
                    <div className={styles["eposide-section-height"]}>
                      {visibleItems.map((item, index) => {
                        const headline = get(item, ["story", "headline"], "");
                        const heroImageKey = get(item, ["story", "hero-image-s3-key"], "");
                        // const heroImageUrl = `https://media.vikatan.com/${heroImageKey}`;
                        const story = get(item, ["story"]);
                        const storyId = get(item, ["story", "id"]);
                        const isCurrentStory = this.state.currentStoryId === storyId;
                        const slug = get(item, ["story", "slug"]);
                        //   const formattedSlug = slug && slug.split("/").pop(); // Extract the last part of the slug
                        const duration = get(item, ["story", "metadata", "story-attributes", "audiolength"], 0);

                        return (
                          <div key={index}>
                            <Link
                              href={`/vikatan-play/${collectionSlug}/${slug}`}
                              className={styles["item-container"]}
                              callback={() => {
                                this.handlePlay(story);
                              }}
                            >
                              <div className={styles["episodes-image"]}>
                                <ResponsiveImageWithFallback
                                  className={styles["image-wrapper"]}
                                  slug={heroImageKey}
                                  metadata={headline}
                                  alt={headline}
                                  imgParams={{ auto: ["format", "compress"] }}
                                  sources={generateImageSources(
                                    { aspectRatio: [3, 3], screenWidthCoverage: 0.75 },
                                    { aspectRatio: [3, 3], screenWidthCoverage: 0.3 }
                                  )}
                                />
                              </div>
                              <div className={styles["audio-story-content"]}>
                                <h5>{headline}</h5>
                                <div className={styles["audio-story-timer"]}>
                                  <div className={styles["timer"]}>
                                    <Icon type="icon-timer" /> <span>{this.formatTime(duration)}</span>
                                  </div>
                                  <div className={styles["audio-controls-top-section"]}>
                                    <div className={`${styles["audio-control-icons"]}`}>
                                      {isCurrentStory && this.state.playing ? (
                                        <Icon type="pause" className={`${styles["pause"]} ${styles["audio-icon"]}`} />
                                      ) : (
                                        <Icon type="play" className={`${styles["play"]} ${styles["audio-icon"]}`} />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                      {hasMoreStories && (
                        <button onClick={this.handleLoadMore} className={styles["load-more-button"]} disabled={loading}>
                          {loading ? "Loading..." : "Load More"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles["loader-wrapper"]}>
              <img alt="Vikatan" src={assetify(loader)} className={styles["loader"]} />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

AudioMagazineEpisode.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    magazine: PropTypes.array
  }),
  pageType: PropTypes.string,
  config: PropTypes.shape({
    disableAds: PropTypes.bool,
    contentInsightsId: PropTypes.string
  })
};

function mapDispatchToProps(dispatch) {
  return {
    setMiniPlayerVisibility: isVisible => {
      dispatch({
        type: "SET_MINI_PLAYER_VISIBILITY",
        payload: isVisible
      });
    },
    setAudioplaying: isVisible => {
      dispatch({
        type: "AUDIO_PLAYING",
        payload: isVisible
      });
    },
    setCurrentStory: story => {
      dispatch({
        type: "CURRENT_STORY",
        payload: story
      });
    },
    setCurrentCollection: collection => {
      dispatch({
        type: "CURRENT_COLLECTION",
        payload: collection
      });
    },
    setClosePlayer: isVisible => {
      dispatch({
        type: "CLOSE_PLAYER",
        payload: isVisible
      });
    }
  };
}

function mapStateToProps(state) {
  return {
    data: get(state, ["qt", "data"]),
    enablePlayer: get(state, ["ShowPlayer"]),
    PlayAudio: get(state, ["PlayAudio"]),
    ClosePlayer: get(state, ["ClosePlayer"]),
    currentPlayingStory: get(state, ["CurrentStory"]),
    jwtKey: get(state, ["qt", "config", "JwtKey"])
  };
}

const AudioMagazineEpisodePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AudioMagazineEpisode);

export { AudioMagazineEpisodePage as AudioMagazineEpisode };
