import React from "react";
import PropTypes from "prop-types";

import { addWithoutAdsToTemplates } from "../get-collection-template";
import { LazyCollection } from "@quintype/components";
import { GA, WebEngage, DeepBI, PGAD } from "../../integrations";
import styles from "./audio-magazine.m.css";
import AudioMenu from "../rows/audio-menu";
const newTemplates = addWithoutAdsToTemplates("home");

function getAdsWrappedCollectionTemplate(designTemplate, index) {
  return newTemplates["audio-collection-2"];
}

export class AudioMagazineSearchPage extends React.Component {
  constructor() {
    super();
    this.state = { filter: null, currentIndex: 0, action: false };
    this.iframeWidgetsRef = React.createRef();
    this.pointtableWidgetsRef = React.createRef();
    this.StoryCollectionWidgetsRef = React.createRef();
  }
  componentDidMount() {
    GA.registerPageView(this.props.pageType, {});
    WebEngage.registerPageView();
    DeepBI.registerPageView({ collection: this.props });
    PGAD.loadScript();
  }

  render() {
    const thirdItem = this.props.data.collection.items[2];

    const collectionWithThirdItem = {
      ...this.props.data.collection,
      items: [thirdItem]
    };

    return (
      <React.Fragment>
        <div className={styles["audio-magazine-main-page"]}>
          <div className={`js-story-main-container ${`${styles["base"]}`}`}>
            <div className={`${styles["audio-magazine-page-container"]} container`}>
              <div ref={this.StoryCollectionWidgetsRef} className={styles["page-space"]}>
                <h1 className={styles["page-title"]}>Audio Books Category</h1>
                <AudioMenu />
                <LazyCollection
                  collection={collectionWithThirdItem}
                  config={this.props.config}
                  collectionTemplates={getAdsWrappedCollectionTemplate}
                  lazyAfter={3}
                  accentColor={"#2f81cd"}
                  pageType={"Audio Magazine Search"}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AudioMagazineSearchPage.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    magazine: PropTypes.array
  }),
  pageType: PropTypes.string,
  searchcategory: PropTypes.bool,
  config: PropTypes.shape({
    disableAds: PropTypes.bool,
    contentInsightsId: PropTypes.string
  }),
  collection: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string
      })
    ).isRequired
  }).isRequired
};
