import React from "react";
import PropTypes from "prop-types";

import { addWithoutAdsToTemplates } from "../get-collection-template";
import { LazyCollection } from "@quintype/components";
import { GA, WebEngage, DeepBI, PGAD } from "../../integrations";
import styles from "./ipl.m.css";
import YoutubeVideoGallery from "./story/YoutubeReels";
import Cookies from "universal-cookie";
// import IPLMatchList from "../rows/ipl-match-list";
import IplPointTable2025 from "../rows/ipl-point-table-2025";
const newTemplates = addWithoutAdsToTemplates("home");
function getAdsWrappedCollectionTemplate(designTemplate) {
  return newTemplates[designTemplate] || newTemplates.defaultTemplate;
}

export class IPL2025Page extends React.Component {
  constructor() {
    super();
    this.state = { filter: null, currentIndex: 0, action: false };
    this.iframeWidgetsRef = React.createRef();
    this.pointtableWidgetsRef = React.createRef();
    this.StoryCollectionWidgetsRef = React.createRef();
  }
  componentDidMount() {
    GA.registerPageView(this.props.pageType, {});
    WebEngage.registerPageView();
    DeepBI.registerPageView({ collection: this.props });
    PGAD.loadScript();
  }

  handleFilter = (slug, index) => {
    this.setState({ filter: slug, currentIndex: index, action: false });
    setTimeout(() => this.setState({ action: true }), 500);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  handleFilter = slug => {
    this.setState({ filter: slug });

    setTimeout(() => {
      if (slug === "Play Quiz" && this.iframeWidgetsRef.current) {
        const iframeWidgetsTop = this.iframeWidgetsRef.current.offsetTop;
        window.scrollTo({
          top: iframeWidgetsTop,
          behavior: "smooth"
        });
      } else if (slug === "Points Table" && this.pointtableWidgetsRef.current) {
        const pointtableWidgetsTop = this.pointtableWidgetsRef.current.offsetTop;
        window.scrollTo({
          top: pointtableWidgetsTop,
          behavior: "smooth"
        });
      } else if (slug === "Worldcup Updates" && this.StoryCollectionWidgetsRef.current) {
        const StoryCollectionWidgetsTop = this.StoryCollectionWidgetsRef.current.offsetTop;
        window.scrollTo({
          top: StoryCollectionWidgetsTop,
          behavior: "smooth"
        });
      }
    }, 500);
  };

  handleSharePage = () => {
    if (navigator.share) {
      const sharedText =
        "🏏 கிரிக்கெட்டில் கில்லியா நீங்க? `சிக்ஸர் அடி... ஐபோன் ரெடி!' Quiz-ல் கலந்துகொண்டு ஐபோன் 13 பரிசா வெல்லுங்க! 🏆 \n \n Click here to Participate:";
      navigator
        .share({
          title:
            "🏏 கிரிக்கெட்டில் கில்லியா நீங்க? `சிக்ஸர் அடி... ஐபோன் ரெடி!' Quiz-ல் கலந்துகொண்டு ஐபோன் 13 பரிசா வெல்லுங்க! 🏆",
          text: sharedText,
          url: window.location.href
        })
        .then(() => {
          console.log("Successfully shared");
          this.hanldeAction("more share");
        })
        .catch(error => {
          console.error("Something went wrong sharing the blog", error);
        });
    }
  };

  render() {
    const cookies = new Cookies();
    const vuid = cookies.get("vuid");
    return (
      <React.Fragment>
        <div
          className={`main-page styles-m__base__2vtxy js-story-main-container ${`${styles["base-class"]} ${
            styles["worldcup-page"]
          }`}`}
        >
          <div className="page-content infinity-page">
            <div className={styles["cricekt-header"]}>
              <ul>
                <li onClick={() => this.handleFilter("Play Quiz")}>Play Quiz</li>
                {/* <li onClick={() => this.handleFilter("Points Table")}>Points Table</li> */}
                <li onClick={() => this.handleFilter("Worldcup Updates")}>IPL Updates</li>
              </ul>
            </div>
            <div className={`${styles["ipl-banner-section-2025"]}`}>
              <div className={`${styles["ipl-banner-section-2025-container"]} container`}>
                <div className={styles["banner-sec"]}>
                  <div className={styles["banner-logo-sec"]}>
                    <div className={styles["sathya-logo"]}>
                      <a
                        href="https://www.sathya.store/?utm_source=Eywa&amp;utm_medium=QR_Code&amp;utm_campaign=Sathya_Website_Vikatan_Ad"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img
                          src="https://media.vikatan.com/vikatan/2024-03/61b8864b-846c-4573-82c5-726d86d0bca2/sathya_logo_slogen.png?format=webp&w=200&dpr=1.0"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className={styles["sporta-logo"]}>
                      <img
                        src="https://media.vikatan.com/vikatan/2023-10/aa815208-4e9c-4aef-8974-1d7d6646cc2e/sports_vikatan.png?format=webp&w=120&dpr=1.0"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className={styles["presents"]}>Presents</div>
                </div>
                <div>
                  <div
                    className={`${styles["banner-bottom-sec"]} ${styles["icc-banner-bottom-sec"]} ${
                      styles["icc-banner-bottom-sec-ipl"]
                    }`}
                  >
                    <div>
                      <img
                        src="https://media.vikatan.com/vikatan/2025-03-21/pjy76xul/ipl-2025-sizer.png?format=webp&w=600&dpr=1.0"
                        alt=""
                      />
                    </div>
                    <div>
                      <img src="https://media.vikatan.com/vikatan/2025-03-21/ga9azfpn/participate-ipl-20215.png?format=webp&w=600&dpr=1.0" />
                    </div>
                  </div>

                  {/* <div className={styles["gt-holidays"]}>
                    <p>Powered By</p>
                    <img src="https://media.vikatan.com/vikatan/2024-03/1a882512-bf6a-460d-a275-2385a71fa94d/gt_logo.png?format=webp&w=300&dpr=1.0" />
                  </div> */}
                  <div
                    className={`${styles["banner-botton-overley"]} ${styles["t-banner-botton-overley"]} ${
                      styles["icc-banner-botton-overley"]
                    }`}
                  >
                    <div className={`${styles["banner-bottom-prices"]} ${styles["icc-banner-bottom-prices"]}`}>
                      <div className={`${styles["other-prices"]} ${styles["icc-prices"]}`}>
                        <div>
                          <img
                            src="https://media.vikatan.com/vikatan/2025-03-21/kuj7f2wv/1st-price.png?format=webp&w=360&dpr=1.0"
                            alt=""
                          />
                        </div>
                        <div>
                          <img
                            src="https://media.vikatan.com/vikatan/2025-03-21/ctynlhnm/2nd.png?format=webp&w=360&q=90"
                            alt=""
                          />
                        </div>
                        <div>
                          <img
                            src="https://media.vikatan.com/vikatan/2025-03-27/3s7nu8s2/3rd-price.png?format=webp&w=360&q=90"
                            alt=""
                          />
                        </div>
                        <div>
                          <img
                            src="https://media.vikatan.com/vikatan/2025-03-21/l8upb5xw/4th.png?format=webp&w=360&q=90"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`container ${styles["story-grid"]}`}>
              {/* <div className={styles["page-share-sec"]} onClick={this.handleSharePage}>
                <div className={styles["page-share"]}>
                  <Icon type="share-alt" className={styles["share-icon"]} />
                  <span className={styles["share-text"]}>Share</span>
                </div>
              </div> */}

              <div className={styles["iframe-widgets-ipl"]} ref={this.iframeWidgetsRef}>
                <div className={`${styles["iframe-widgets-wc"]} container`}>
                  <div className={styles["iframe-widget-quiz"]}>
                    {vuid ? (
                      <div>
                        <iframe src="https://special.vikatan.com/ipl/quiz/index.php" alt="" />
                      </div>
                    ) : (
                      <div>
                        <a
                          href="https://www.vikatan.com/user/login?redirect_url=https://sports.vikatan.com/cricket/ipl-2025"
                          className={styles["login-img"]}
                        >
                          <img src="https://media.vikatan.com/vikatan/2025-03-21/7yrtfarh/VikatanSathyaIPL2025bannerQuizLoginBanner.png" />
                        </a>
                      </div>
                    )}
                  </div>
                  <div className={styles["iframe-widget-scoreboard"]}>
                    <iframe src="https://special.vikatan.com/ipl/quiz/scoreboard.php" alt="" />
                  </div>
                </div>

                {/* <div className={styles["iframe-widget-quiz"]}>
                  {vuid ? (
                    <div>
                      <iframe src="https://special.vikatan.com/ipl/quiz/index.php" alt="" />
                    </div>
                  ) : (
                    <div>
                      <a
                        href="https://login.vikatan.com?redirect_url=https://sports.vikatan.com/cricket/ipl"
                        className={styles["login-img"]}
                      >
                        <img src="https://media.vikatan.com/vikatan/2024-03/ff2c52a5-1cb0-446b-8e02-b32daf46872d/ipl_login_banner.png" />
                      </a>
                    </div>
                  )}
                </div> */}

                {/* <h2 className={styles["winners-title"]}>The Winners are</h2>
                <div className={styles["winners-list"]}>
                  <div>
                    <img src="https://media.vikatan.com/vikatan/2024-07/4ceff62b-c6ef-41a5-bdb6-e10eca5f3781/winner_rohit.png?format=webp&w=200&dpr=1.0" />
                    <h3>ரோஹித்</h3>
                    <p className={styles["winners-dist"]}>சென்னை</p>
                  </div>
                  <div>
                    <img src="https://media.vikatan.com/vikatan/2024-07/60d107a7-0708-418b-9da6-8e6ae48fa761/winner_ramadhas.png?format=webp&w=200&dpr=1.0" />
                    <h3>ராமதாஸ்</h3>
                    <p className={styles["winners-dist"]}>கோவை</p>
                  </div>
                  <div>
                    <img src="https://media.vikatan.com/vikatan/2024-07/e28d2bf3-4160-4a83-b6dc-f4be994a06a2/winner_abilash.png?format=webp&w=200&dpr=1.0" />
                    <h3>அபிலாஷ்</h3>
                    <p className={styles["winners-dist"]}>மார்த்தாண்டம்</p>
                  </div>
                </div> */}
              </div>

              <div ref={this.pointtableWidgetsRef}>
                <IplPointTable2025 />
              </div>

              {/* <div>
                <IPLMatchList title="IPL 2025 Match Schedule" titleClassName="blue-theme" />
              </div> */}

              <div className={styles["main-content"]} ref={this.StoryCollectionWidgetsRef}>
                <LazyCollection
                  collection={this.props.data.collection}
                  config={this.props.config}
                  collectionTemplates={getAdsWrappedCollectionTemplate}
                  lazyAfter={1}
                  accentColor={"#2f81cd"}
                  pageType={"cricket"}
                />
              </div>

              <YoutubeVideoGallery title="IPL Reels" titleClassName="blue-theme" />

              <div className={styles["terms-wrapper"]}>
                <div className="container">
                  <div className={styles["social-wrapper"]}>
                    <div className={styles["social-media-wrap"]}>
                      <h3>Follow Sathya: </h3>
                      <div>
                        <a href="https://www.facebook.com/sathya.retail" rel="noopener noreferrer" target="_blank">
                          <img
                            src="https://media.vikatan.com/vikatan/2024-03/78184621-9290-492c-b851-1bcde30d0b82/ipl_fb.png"
                            alt="sathya Facebook"
                          />
                        </a>
                      </div>
                      <div>
                        <a href="https://www.instagram.com/sathya.retail/" rel="noopener noreferrer" target="_blank">
                          <img
                            src="https://media.vikatan.com/vikatan/2024-03/aa228433-397b-40b8-899f-37f2840aa875/ipl_instagram.png"
                            alt="sathya Instagram"
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://adtarbo.eywamedia.com/qr/scan?c=6529256630383"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            src="https://media.vikatan.com/vikatan/2024-03/621beace-7c5a-4ed0-a5f7-02a0d1634035/ipl_whatsapp.png"
                            alt="sathya Whatsapp"
                          />
                        </a>
                      </div>
                    </div>
                    <div className={styles["social-media-wrap"]}>
                      <h3>Follow Vikatan: </h3>
                      <div>
                        <a href="https://www.facebook.com/SportsVikatan/" rel="noopener noreferrer" target="_blank">
                          <img
                            src="https://media.vikatan.com/vikatan/2024-03/78184621-9290-492c-b851-1bcde30d0b82/ipl_fb.png"
                            alt="Vikatan Facebook"
                          />
                        </a>
                      </div>
                      <div>
                        <a href="https://www.instagram.com/sportsvikatan/" rel="noopener noreferrer" target="_blank">
                          <img
                            src="https://media.vikatan.com/vikatan/2024-03/aa228433-397b-40b8-899f-37f2840aa875/ipl_instagram.png"
                            alt="Vikatan Instagram"
                          />
                        </a>
                      </div>
                      <div>
                        <a href="https://twitter.com/sportsvikatan" rel="noopener noreferrer" target="_blank">
                          <img
                            src="https://media.vikatan.com/vikatan/2024-03/2da849cb-adcd-45c1-8769-3ecf3421f303/ipl_twitter.png"
                            alt="Vikatan Twitter"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={styles["terms-points"]}>
                    <h2>
                      <a
                        href="https://www.vikatan.com/cricket-quiz-terms-and-conditions"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <span>*</span> Terms and Conditions
                      </a>
                    </h2>
                    <div className={styles["terms-list"]}>
                      <h3>Disclaimer:</h3>
                      <p>
                        1. By participating in the &quot;Sixer Adi,&quot; (&quot;Contest&quot;), you acknowledge and
                        agree to abide by the following Terms and Conditions*. Vikatan reserves the right to modify or
                        update these terms* at any time without prior notice.
                      </p>
                      <p>
                        2. The Contest is organized solely for entertainment and educational purposes. It is not
                        associated with, endorsed by, or affiliated with any specific sports organization, team, or
                        governing body.
                      </p>
                      <p>
                        3. Only one participant per family is allowed. Any violation of this rule may result in
                        disqualification of the contestant(s) from receiving the final prizes, at the discretion of the
                        authority.
                      </p>
                      <p>
                        4. By participating in this contest you agree to be bound by the Terms and conditions provided
                        in the Link. If you do not agree to with any part of the terms, please do not participate.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div />
        </div>
      </React.Fragment>
    );
  }
}

IPL2025Page.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    magazine: PropTypes.array
  }),
  pageType: PropTypes.string,
  config: PropTypes.shape({
    disableAds: PropTypes.bool,
    contentInsightsId: PropTypes.string
  })
};
