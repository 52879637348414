import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import SmallStoryCardCurve from "../../atoms/small-story-card-with-curve";
import styles from "./styles.m.css";
import { WebEngage, GA, DeepBI, PGAD } from "../../../integrations";
import { VikatanSticky, PGAdwithAd } from "../../rows/ad-free";
import Media from "react-media";
import MagazineStaticOneStory from "../../rows/magazine-static-one-story";

const getStoriesByLabel = collection => {
  return collection.items.filter(({ story = {} }) => story.headline && story.url).reduce((storyMap, item) => {
    const label = get(item, ["associated-metadata", "label"], "மற்றவை");
    if (storyMap[label]) {
      storyMap[label].push(item.story);
    } else {
      storyMap[label] = [item.story];
    }
    return storyMap;
  }, {});
};

const magazineGridList = storyMap => {
  // const storyMap = issueCollection && getStoriesByLabel(issueCollection);

  const rootMenuGroup =
    storyMap &&
    Object.keys(storyMap)
      .map((label, idx) => {
        if (label !== "கவர் ஸ்டோரி") {
          return {
            title: label,
            id: idx,
            items: storyMap[label].slice(1).map(storyObject => {
              return storyObject;
            })
          };
        }
      })
      .filter(res => res);
  const menuTitle =
    storyMap &&
    rootMenuGroup.map((item, id) => {
      return (
        <div key={id}>
          <div className={styles["card-title"]}>{item.title}</div>
          <div className={styles["magazine-card-grid-wrapper"]}>
            {item.items.map((story, ids) => (
              <div key={ids} className={styles["magazine-card"]}>
                <SmallStoryCardCurve story={story} setmagazineUTM="magazine-page" />
              </div>
            ))}
          </div>
          {id % 3 === 0 && (
            <PGAdwithAd
              adId={`Vikatan2023_Mobile_SP_MID1_336x280`}
              adSize={[[336, 280], [300, 250], [320, 100], [320, 50]]}
              minHeight={280}
              adDivId="div-ad-desk-3849069-1690280872-1"
              class="ad_300_250 hidden-desktop"
            />
          )}
        </div>
      );
    });

  return menuTitle;
};

export const NTPage = ({ pageType, config, data: { collection } }) => {
  React.useEffect(() => {
    WebEngage.registerPageView();

    GA.registerPageView(pageType || "magazine-page", { collection });
    DeepBI.registerPageView({
      collection: { data: { collection: collection }, pageType: pageType || "magazine-page" }
    });
    PGAD.loadScript();
  }, []);
  if (!collection) return null;
  const storyMap = getStoriesByLabel(collection);

  return (
    <div className={`${styles["main-container"]}`}>
      <div className={styles.base}>
        <MagazineStaticOneStory collection={collection} config={config} />
        <div>
          <Media query="(max-width:767px)">
            <PGAdwithAd
              adId={`Vikatan2023_Desktop_AP_RightMID_336x280`}
              adSize={[[336, 280], [300, 250]]}
              minHeight={250}
              adDivId="div-ad-desk-3849069-877415-12"
              class="ad_300_250"
            />
          </Media>
        </div>
        <div className={`${styles["magazine-listing"]}`}>
          <div className={`container`}>
            {/* <MagazineSixColumnGrid stories={stories} /> */}
            {magazineGridList(storyMap)}
          </div>
        </div>
      </div>
      <div className={styles["magazine-ad"]}>
        <VikatanSticky placement="bundle" />
      </div>
    </div>
  );
};

NTPage.propTypes = {
  pageType: PropTypes.string,
  config: PropTypes.shape({
    contentInsightsId: PropTypes.string,
    magazines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        magazineSlug: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        vikatanMagazineId: PropTypes.number
      })
    ),
    "publisher-name": PropTypes.string
  }),
  data: PropTypes.shape({
    oldIssues: PropTypes.arrayOf(PropTypes.any),
    config: PropTypes.shape({
      section: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          slug: PropTypes.string
        })
      ),
      magazines: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          magazineSlug: PropTypes.string,
          name: PropTypes.string,
          url: PropTypes.string,
          vikatanMagazineId: PropTypes.number
        })
      ),
      "publisher-name": PropTypes.string
    }),
    collection: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          "associated-metadata": PropTypes.shape({
            label: PropTypes.string,
            "page-no": PropTypes.string
          }),
          story: PropTypes.shape({
            id: PropTypes.string,
            contributors: PropTypes.arrayOf(
              PropTypes.shape({
                "role-name": PropTypes.string,
                authors: PropTypes.arrayOf(
                  PropTypes.shape({
                    name: PropTypes.string
                  })
                )
              })
            ),
            headline: PropTypes.string.isRequired,
            alternative: PropTypes.shape({
              home: PropTypes.shape({
                default: PropTypes.shape({
                  headline: PropTypes.string
                })
              })
            }),
            slug: PropTypes.string.isRequired,
            "hero-image-metadata": PropTypes.shape({
              height: PropTypes.number,
              width: PropTypes.number,
              "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
            }),
            "hero-image-s3-key": PropTypes.string,
            "hero-image-caption": PropTypes.string
          })
        })
      )
    })
  })
};

export default NTPage;
