import React from "react";
import PropTypes from "prop-types";
import CollectionLink from "../../utils/generate-collection-link";
import CollectionTitleWithSideLine from "../../atoms/CollectionTitleWithSideLine";
import StoryCardML4x3DP1x1 from "../../atoms/story-card-ml4x3-dl1x1";
import Iframe from "react-iframe";
import Collapsible from "react-collapsible";

import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";
import { PGAdwithAd } from "../ad-free";

const SilverStory = ({ collection, accentColor }) => {
  const { name, items } = collection;
  const stories =
    items
      .filter(({ story = {} }) => getStoryHeadline(story) && story.url)
      .slice(0, 4)
      .map(({ story }) => <StoryCardML4x3DP1x1 className={styles["story-card"]} key={story.id} story={story} />) || [];

  if (!stories.length) {
    return null;
  }

  return (
    <div className={`${styles["main-container"]}`} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container`}>
        <div className={styles["iframe-card-container"]}>
          <Iframe
            frameBorder="0"
            className={styles["iframe-outer"]}
            src="https://special.vikatan.com/gold-page/?view=silver"
          />
        </div>
        <CollectionLink collection={collection}>
          <CollectionTitleWithSideLine className={styles.title} title={name} />
        </CollectionLink>
        <div className={styles["story-card-container"]}>
          <div className={styles["bg-story-container"]}>{stories}</div>
          <div className={styles["sidebar-story-container"]}>
            <div className={styles["small-card-ad-container"]}>
              <PGAdwithAd
                adId={`${styles.ad}`}
                adSize={[[336, 280], [300, 250]]}
                minHeight={280}
                adDivId="div-ad-desk-3849069-169028051743-4"
                class=""
              />
            </div>
          </div>
        </div>
        <CollectionTitleWithSideLine className={styles.title} title="Silver Rate FAQ" />
        <div className={styles["story-card-container"]}>
          <div className={styles["story-container-faq"]}>
            <Collapsible trigger="1. சென்னையில் 1 கிராம் வெள்ளியின் விலை என்ன?">
              <p>இன்றைய நிலவரப்படி, சென்னையில் 1 கிராம் வெள்ளியின் விலை</p>
            </Collapsible>
            <Collapsible trigger="2. தங்கத்தின் விலை எவ்வாறு கணக்கிடப்படுகிறது?">
              <p>
                தங்கத்தின் விலையானது இரண்டு காரணிகளைச் சார்ந்தது. முதலாவது நகையில் இருக்கும் தங்கத்தின் அளவை பொறுத்தது
                (22 கேரட் அல்லது 18 கேரட்). இரண்டாவது தங்கத்தோடு கலக்கப் பயன்படும் உலோகத்தைப் பொறுத்தது. தினமும்
                தங்கமானது பங்குச்சந்தையில் வர்த்தகம் செய்யப்படும். இதனால் தேவை மற்றும் விநியோகம் சார்ந்து இதன் விலை
                தினமும் மாறுபடும். ஒவ்வொரு நகை உரிமையாளர்க்கும் அவர் யாரிடம் தங்கம் வாங்குகிறார் என்பதைப் பொறுத்து அதன்
                விலையானது மாறுபடும். தங்கத்தின் விலையானது இறுதியாக - தங்கத்தின் விலை (22 கேரட் அல்லது 18 கேரட் ) ×
                தங்கத்தின் எடை கிராமில் + நகை செய்வதற்கு ஆகும் செலவு + 3% ஜிஎஸ்டி ( நகையின் விலை + நகை செய்வதற்காகும்
                விலை).
              </p>
            </Collapsible>
            <Collapsible trigger="3. தங்கத்தின் மீதான ஜிஎஸ்டி வரி எவ்வளவு?">
              <p>
                தங்கத்தின் மீதான ஜிஎஸ்டியானது 3 சதவிகிதம் ஆகும். அதே சமயத்தில் தங்க நகை என்றால் கூடுதலாக 5 சதவிகித
                ஜிஎஸ்டி தங்க நகை செய்வதற்காகக் கணக்கிடப்படும்.
              </p>
            </Collapsible>
            <Collapsible trigger="4. தங்கத்தில் 22 கேரட் தங்கம் என்றால் என்ன? 24 கேரட் தங்கம் என்றால் என்ன?">
              <div>
                <p>
                  பொதுவாக 24 கேரட் தங்கம் என்பது, முழுவதும் சுத்தமான தங்கமாக இருக்கும். ஆனால் 22 கேரட் தங்கத்தில் 22
                  பகுதிகள் மட்டுமே தூய தங்கத்தின் பொருட்கள் இடம்பெற்றிருக்கும். மீதி இரண்டு பகுதிகளாக வேறு எதாவது உலோகம்
                  கலக்கப்பட்டிருக்கும்.
                </p>
                <p>24 கேரட் தங்கம் = 99.5 % தூய தங்கம்.</p>
                <p>22 கேரட் தங்கம் = 91.7 % தங்கம் + இதர உலோகங்கள்.</p>
              </div>
            </Collapsible>
            <Collapsible trigger="5. இந்தியாவில் மத்திய அரசால் தொடங்கப்பட்டுள்ள தங்கத் திட்டம் எது?">
              <p>
                இந்திய அரசால் கடந்த 2015-ல் சாவ்ரேன் கோல்ட் பாண்டு ஸ்கீம் தொடங்கப்பட்டது. இதன் மூலம் தங்கத்தை
                பயன்படுத்துவதற்குப் பதில், உள்நாட்டு முதலீடாக மாற்றத் திட்டமிட்டது. இந்த சாவ்ரேன் கோல்ட் பாண்டு
                ஸ்கீமானது வங்கிகளிலும், அஞ்சலகங்களிலும்,பங்குச்சந்தையிலும், எஸ்.ஹெச்.சி.ஐ.எல்-லிலும்(SHCIL) கிடைக்கும்.
              </p>
            </Collapsible>
            <Collapsible trigger="6. தங்கத்தின் விலை 2020ல் அதிகரிக்குமா">
              <p>
                தங்கத்தின் மூலம் கடந்த 2019ல் 23.74 சதவிகித வருமானம் கிடைத்தது. இது 2020ல் இன்னும் அதிகரிக்கும் என்றே
                எதிர்பார்க்கப்படுகிறது.இதனால் வருகின்ற நாட்களில் பத்து கிராம் தங்கத்தின் விலையானது ₹ 50,000 முதல் ₹
                55,000 வரை செல்லலாம் என நிபுணர்கள் தெரிவிக்கிறார்கள்.இப்போது வரை மட்டுமே 2020ல் 17.31 சதவிகித வருமானம்
                தங்கம் மூலம் கிடைத்துள்ளது.
              </p>
            </Collapsible>
            <Collapsible trigger="7. தங்க விரயம்  என்றால் என்ன?">
              <p>
                தங்க நகைகளே உருவாக்கும் போது பொற்கொல்லர் அல்லது கைவினை கலைஞர் தங்கத்தை உருக்கி, உருமாற்றிதான் நகையைச்
                செய்வார்கள். இப்படிச் செய்யும் போது சிறிய தங்கத் துகள்கள் வீணாகப் போகலாம். இந்த துகள்கள் அனைத்தும்
                வீணானது கிடையாது. இதிலிருந்து சிதறும் சிறிய துகள்கள் மீண்டும் நகை செய்வதற்கும் பயன்படும். ஆனால் வீணான
                தங்கத்திற்கான சேதார விலையும் நகை விலையிலேயே சேர்க்கப்படும். இந்தியாவில் பொதுவாகத் தங்க விரயமானது 3
                சதவிகிதம் முதல் 35 சதவிகிதம் வரை கணக்கிடப்படுகிறது.
              </p>
            </Collapsible>
            <Collapsible trigger="8. எந்த நாட்டில் தங்கம் மிகவும் விலை உயர்ந்தது?">
              <p>
                இந்தியாவில் தான் தங்கத்தின் விலை மிக உயர்ந்ததாக உள்ளது. இங்கு தான் அதிகமான தங்கம் இறக்குமதி
                செய்யப்படுகிறது. தேவை அதிகமாக இருப்பதாலும், இந்திய மக்களுக்குத் தங்கத்தின் மீது மோகம் அதிகமாக
                இருப்பதாலும் தங்க விலையானது மற்ற நாடுகளை விட இந்தியில் உயர்ந்தே காணப்படுகிறது.
              </p>
            </Collapsible>
            <Collapsible trigger="9. வரலாற்றில் தங்கத்தின் அதிகபட்ச விலை எது?">
              <p>
                வரலாற்றில் தங்கத்தின் அதிகபட்ச விலையானது கடந்த 2011 ல் ஒரு அவுன்ஸின் (31.103 கிராம்) விலையானது 2000
                டாலர் வரை தொட்டது. அது தான் இப்போது வரை தங்கத்தின் உச்சபட்ச விலையாகும்
              </p>
            </Collapsible>
            <Collapsible trigger="10. தங்கத்தின் விலையானது வருகின்ற 2021ல் குறையுமா? ">
              <p>
                இல்லை. தங்கத்தின் விலையானது கொரோனா காலத்திற்குப் பிறகு அதிகமாகும் என நிபுணர்கள் தெரிவிக்கிறார்கள்.
                அடுத்த 2021 ஆண்டு இறுதிக்குள் தங்கத்தின் விலை பத்து கிராமிற்கு 82,000 ரூபாயாக இருக்கும் என பேங்க் ஆஃப்
                அமெரிக்க செக்யூரிட்டீஸ் அதன் சமீபத்திய அறிக்கையில் தெரிவித்துள்ளது.
              </p>
            </Collapsible>
          </div>
        </div>
      </div>
    </div>
  );
};

SilverStory.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardML4x3DP1x1.propTypes))
  })
};
SilverStory.storyLimit = 4;

export default SilverStory;
