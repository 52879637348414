import React from "react";
import PropTypes from "prop-types";
import { Link } from "@quintype/components";

import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";

export default function TrendingStories({
  collection,
  className = "",
  numberOfStories = 4,
  hasTruncatedHeadline = true
}) {
  if (!collection || !collection.items) {
    return null;
  }
  const stories = collection.items.map(item => item.story);
  return (
    <div className={`${styles["numbered-items-wrap"]} ${className}`}>
      {collection.slug &&
        collection.name && (
          <h2 className={styles["trending-title"]}>
            <Link aria-label={`"Read more "${collection.name}`} href={collection.pagePath || collection.slug}>
              {collection.name}
            </Link>
          </h2>
        )}
      <ul className={styles["numbered-items"]}>
        {stories
          .filter(story => story && story.url && getStoryHeadline(story))
          .slice(0, numberOfStories)
          .map(story => (
            <li key={story.id} className={styles["numbered-item"]}>
              <a
                aria-label={`${"Read full story: "} ${getStoryHeadline(story)}`}
                href={`${story.url}?pfrom=homepage-mainrow-trending`}
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles["numbered-item-link"]} ${hasTruncatedHeadline ? styles["truncated"] : ""} `}
              >
                {getStoryHeadline(story)}
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
}

TrendingStories.propTypes = {
  hasTruncatedHeadline: PropTypes.bool,
  className: PropTypes.string,
  numberOfStories: PropTypes.number,
  collection: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        headline: PropTypes.string.number,
        slug: PropTypes.string.number,
        id: PropTypes.string.number
      })
    )
  })
};
