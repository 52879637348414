import React from "react";
import PropTypes from "prop-types";
import CollectionLink from "../../utils/generate-collection-link";
import CollectionTitle from "../../atoms/CollectionTitle";
import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";
import Icon from "../../atoms/icon";
import StoryCardVertical from "../../atoms/story-card-vertical";

const SixStory6cwithcategory = ({
  collection,
  accentColor,
  authorName = true,
  utmURL = "",
  homecollection = false,
  storyLimit
}) => {
  const { name, items } = collection;
  const stories =
    items
      .filter(({ story = {} }) => getStoryHeadline(story) && story.url)
      .slice(0, storyLimit)
      .map(({ story }) => (
        <StoryCardVertical
          className={styles["story-card"]}
          key={story.id}
          story={story}
          authorName={authorName}
          utmURL={utmURL}
          openInNewTab={true}
        />
      )) || [];

  if (!stories.length) {
    return null;
  }

  return (
    <div
      className={`container ${styles["collection-wrapper"]} ${
        homecollection ? styles["home-card"] : styles["not-home-card"]
      }`}
    >
      <CollectionLink collection={collection} className={`${styles["title-grid"]}`}>
        <CollectionTitle className={styles.title} title={name} />
        <Icon type="double-arrow" className={styles["double-arrows"]} />
      </CollectionLink>
      <div className={`${styles["story-container"]}`}>{stories}</div>
    </div>
  );
};

SixStory6cwithcategory.propTypes = {
  accentColor: PropTypes.string,
  authorName: PropTypes.bool,
  utmURL: PropTypes.string,
  homecollection: PropTypes.string,
  storyLimit: PropTypes.bool,
  openInNewTab: PropTypes.bool,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardVertical.propTypes))
  })
};
SixStory6cwithcategory.storyLimit = 8;

export default SixStory6cwithcategory;
