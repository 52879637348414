import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import styles from "./styles.m.css";
import AudioCollectionMenuSlider from "../../atoms/audio-collection-menu-slider";

const AudioMenu = ({
  menu,
  numberOfCardsInViewDesktop = 9,
  numberOfCardsInViewTablet = 2,
  numberOfCardsInViewMobile = 3.5,
  accentColor
}) => {
  let menuItems = get(menu, ["items"], []);

  let stacks =
    menuItems &&
    menuItems.length > 0 &&
    menuItems.map((item, index) => {
      return (
        <AudioCollectionMenuSlider
          key={item.id}
          collection={item}
          className={styles.card}
          numberOfCardsInViewDesktop={numberOfCardsInViewDesktop}
          categoriescard={true}
          audiomenu={true}
        />
      );
    });

  if (!stacks || !stacks.length) {
    return null;
  }

  return (
    <div className={styles["bundle-wrapper"]}>
      <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
        {stacks}
      </div>
    </div>
  );
};

AudioMenu.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number,
  menu: PropTypes.array
};

function mapStateToProps(state) {
  return { menu: get(state, ["qt", "data", "header", "AudioHeaderMenu"]) };
}

export default connect(mapStateToProps)(AudioMenu);
